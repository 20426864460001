<template>
  <auth-card :branding="branding" >
    <template v-slot:subtitle >
      <message-box class="tw-text-xs" :messages="messages"></message-box>
    </template>
    <template v-slot:default >
      <form method='POST' :action="form_url" @submit.prevent='submit_form'>
        <v-text-field v-if="!is_edit_mode" class="field" outlined label="Email" name="portal_user[email]"/>
        <v-text-field v-if="is_edit_mode"
                      class="field"
                      outlined
                      label="New Password"
                      :type="showPassword ? 'text' : 'password'"
                      name="portal_user[password]"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
        />
        <v-text-field v-if="is_edit_mode"
                      class="field"
                      outlined
                      label="Confirm New Password"
                      :type="showPassword ? 'text' : 'password'"
                      name="portal_user[password_confirmation]"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
        />
        <v-btn block
               type="submit"
               dark
               rounded
               color="blue darken-4"

        >
          {{ button_label }}
        </v-btn>
      </form>
    </template>

    <template v-slot:actions >

      <v-spacer></v-spacer>
      <v-btn plain color="blue darken-4" :href="sign_in_url" class="text-capitalize">
        Back to Login
      </v-btn>
    </template>
  </auth-card>
</template>

<script>
import MessageBox from "../alerts/MessageBox";
import AuthCard from "./AuthCard";
import axios from '~shared/Axios'

export default {
  name: "PortalResetPassword",
  components: { MessageBox, AuthCard },
  props: {
    button_label: {type: String, default: "Send Recovery Email"},
    form_url: {required: true},
    sign_in_url: {required: true},
    is_edit_mode: {type: Boolean, default: false},
    reset_password_token: {required: false},
    link_expired_error: {type: String, default: null},
    passwordRcoveryLinkMessage: {type: String, default: ''},
    branding: {
      type: Object,
      default() {
        return {
          clientLogoUrl: "https://via.placeholder.com/193x96",
          sponsoredLogoUrl: "https://via.placeholder.com/133x33"
        }
      }
    }
  },
  created() {
    if (this.link_expired_error != null) {
      this.addMessage({success: false, message: this.link_expired_error})
    }
  },
  data() {
    return {
      message: null,
      messages: [],
      showPassword: false
    }
  },
  methods: {
    addMessage({success, message}) {
      const msgType = success ? "success" : "error";
      this.messages = [{message_type: msgType, text: message}];
    },
    anyMessage() {
      return this.messages.length > 0;
    },
    submit_form(ev) {
      const form = ev.target;
      const form_data = new FormData(form);
      const vm = this;
      if (this.is_edit_mode){
        form_data.append("portal_user[reset_password_token]", this.reset_password_token)
      }

      axios({
        method: form.method,
        url: form.action,
        data: form_data
      })
        .then(response => {
          let data = response.data
          if (response.status == 200 && data.status != 400) {
            if (this.is_edit_mode) {
              vm.addMessage({success: true, message: "Password updated successfully."});
              window.location.href = '/portal';
            } else {
              vm.addMessage({
                success: true,
                message: this.passwordRcoveryLinkMessage
              });
            }
          } else {
            if (this.is_edit_mode) {
              data.errors.map ( e => vm.addMessage({success: false, message: e}) )
            }
          }
        })
        .catch(err => {
          vm.addMessage({success: false, message: "Sorry, there was an error on that request. Please try again."});
        })
    },
  },
};
</script>

<style scoped>
.message {
  min-height: 1rem;
}
</style>
