<template>
  <auth-card :branding="branding">
    <template v-slot:subtitle >
      <message-box class="tw-text-xs" :messages="messages"></message-box>
    </template>
    <template v-slot:default >
      <form method='POST' :action="form_url" @submit.prevent='submit_form'>
      <input type='hidden' name='authenticity_token' :value='auth_token'>
      <input name="utf8" type="hidden" value="✓">

      <v-text-field class="field"
                    outlined label="Email"
                    name="portal_user[email]"
                    placeholder=" "
                    persistent-placeholder
      />
      <v-text-field class="field"
                    outlined
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    name="portal_user[password]"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    placeholder=" "
                    persistent-placeholder

      />
      <v-btn block
             type="submit"
             dark
             rounded
             color="blue darken-4"

      >
        {{ button_label }}
      </v-btn>
    </form>
    </template>
    <template v-slot:actions >
      <!--
      <v-btn plain color="blue darken-4" :href="sign_up_url" class="text-capitalize">
        Create a new account
      </v-btn>
      -->

      <v-spacer></v-spacer>
      <v-btn plain color="blue darken-4" :href="reset_password_url" class="text-capitalize">
        Forgot Password?
      </v-btn>
    </template>

  </auth-card>
</template>

<script>
import MessageBox from "../alerts/MessageBox";
import AuthCard from "./AuthCard";

export default {
  name: "PortalSignin",
  components: { MessageBox, AuthCard },
  props: {
    button_label: {type: String, default: "Sign In"},
    title: {type: String, default: "Sign In"},
    auth_token: {type: String},
    form_url: {required: true},
    sign_up_url: {required: true},
    reset_password_url: {required: true},
    rememberable: {type: Boolean, default: false},
    branding: {
      type: Object,
      default() {
        return {
          clientLogoUrl: "https://via.placeholder.com/193x96",
          sponsoredLogoUrl: "https://via.placeholder.com/133x33"
        }
      }
    },
    server_messages: {type: Array, default: []}
  },

  data() {
    return {
      showPassword: false,
      showSignUp: false,
      message: null,
      messages: this.server_messages // for now but we may want to process these to ensure they are in right format
    }
  },

  methods: {
    addMessage({success, message}) {
      const msgType = success ? "success" : "error";
      this.messages = [{message_type: msgType, text: message}];
    },
    anyMessage() {
      return this.messages.length > 0;
    },
    submit_form(ev) {
      const form = ev.target;
      const form_data = new FormData(form);
      const vm = this;

      fetch(form.action, {
        method: form.method,
        body: form_data
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            window.location = data.redirect_to;
          } else {
            if (data?.error) {
              vm.addMessage({success: false, message: data.error});
            } else if(data?.message) {
              vm.addMessage(data);
            } else {
              throw new Error("No error or message returned from server")
            }
          }
        })
        .catch(err => {
          console.error(err)
          vm.addMessage({success: false, message: "Sorry, there was an error on that request. Please try again."});
        })
    },
  },
};

</script>

<style scoped>
.message {
  min-height: 1rem;
}
</style>
