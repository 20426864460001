<template>
<div key="document-messages">
  <v-alert
      v-if="messages.length > 1"
      dense
      outlined
      :type="messages[0].message_type"
  >
    <span v-for="(message,index) in messages"
          :key="index"> {{ message.text }}  <br></span>
  </v-alert>

  <v-alert
      v-if="messages.length == 1"
      dense
      outlined
      v-for="(message,index) in messages"
      :key="index"
      :type="message.message_type"
  >
    {{ message.text }}
  </v-alert>
</div>

</template>

<script>
export default {
  name: "MessageBox",
  props: {
    messages: {
      type: Array,
      default(){
        return [];
      }
    }
  }
}
</script>

<style scoped>

</style>