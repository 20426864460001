import axios from '~shared/Axios'
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const state = {
    demographics: {},
    address: {},
    contact: {},
    user: {},
    grouped_patients: [],
    current_step: 1,
    bufferValue: 0,
    isOnboarding: false,
    subtitles: {},
    commons: {},
    windowWidth: '',
    tos_path: '',
    hasAlert: false,
    alertMessage: "",
    alertStatus: "success",
    messageRegardingList: [],
    selectedRegarding: {},
    messageRecipientsList: [],
    selectedRecipient: {},
    messageData: {},
    showRecipients: true,
    mostRecentMessages: [],
    selectedConversationId: "",
    selectedConversation: null,
    isRequestComplete: false
};

const DEMOGRAPHICS_PATH = "/portal/demographics";
const ADDRESS_PATH = "/portal/addresses";
const CONTACTS_PATH = "/portal/contacts";

const GET_REGARDING_LIST_PATH = "/portal/messaging/conversation_references/get_selectable"
const GET_RECIPIENTS_LIST_PATH = "/portal/messaging/conversation_participants/get_selectable"
const GET_MOST_RECENT_MESSAGES_PER_CONVERSATION_PATH = "/portal/messaging/messages/list_most_recent_per_conversation"
const CONVERSATION_BASE_PATH = "/portal/messaging/conversations"

const config = {
    headers: {
        "Content-Type": "application/json",
    },
    onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
        );
        state.bufferValue = percentCompleted;
    },
    onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
        );
        state.bufferValue = percentCompleted;
    },
};

const getters = {
    demographics: (state) => state.demographics,
    address: (state) => state.address,
    contact: (state) => state.contact,
    hasDemographics(state) {
        return state.demographics.id != null;
    },
    selectedPatient() {
        return state.user.patients.find(pat => {
            return pat.selected
        })
    },
    hasAlert: (state) => state.hasAlert,
    alertMessage: (state) => state.alertMessage,
    alertStatus: (state) => state.alertStatus,
};

const actions = {
    resetSelectedConversation({commit}) {
        state.selectedConversationId = null
        state.selectedConversation = null
    },
    setUser({commit}, data) {
        commit("SET_USER", data.user);
        return new Promise((resolve, reject) => {
            resolve("User set");
        });
    },
    composeMessage({commit}) {
        state.isRequestComplete = false
        axios.post(
            CONVERSATION_BASE_PATH,
            state.messageData,
            config
        )
            .then(
                (response) => {
                    state.hasAlert = true
                    state.alertStatus = "success"
                    state.alertMessage = "Sent!"
                    state.isRequestComplete = true
                },
                (error) => {
                    state.hasAlert = true
                    state.alertStatus = "error"
                    state.alertMessage = "The message was not sent. Please try again."
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                state.hasAlert = true
                state.alertStatus = "error"
                state.alertMessage = "The message was not sent. Please try again."
                console.log(error);
                state.isRequestComplete = true
            });
    },
    getMessageRegardingList({commit}) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            portal_users_id: state.user.id,
        };
        axios
            .get(GET_REGARDING_LIST_PATH, query)
            .then(
                (response) => {
                    const selectableReferences = formatData(response.data.selectable_references)
                    commit("SET_REGARDING_LIST", selectableReferences);
                    if (selectableReferences.length === 1) {
                        this.dispatch("getMessageRecipientsList", selectableReferences[0]);
                    }
                    state.isRequestComplete = true
                },
                (error) => {
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    getMessageRecipientsList({commit}, item) {
        if (!item)
            return

        state.isRequestComplete = false

        let query = Object.assign({}, config);
        query.params = {
            portal_users_id: state.user.id,
            reference_id: item.id,
            per_page: 10000
        };
        axios
            .get(GET_RECIPIENTS_LIST_PATH, query)
            .then(
                (response) => {
                    const selectedParticipants = formatData(response.data.selectable_participants)
                    state.messageRecipientsList = selectedParticipants && selectedParticipants.length > 0
                    commit("SET_RECIPIENTS_LIST", selectedParticipants);
                    state.isRequestComplete = true
                },
                (error) => {
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    getMostRecentMessagesPerConversation({commit}, params) {
        state.isRequestComplete = false
        state.selectedConversation = null
        state.selectedConversationId = null
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
            ...(params.hasOwnProperty('isArchived')) && {archived: params.isArchived},
            ...(params.search_term && params.search_term != "") && {search_term: params.search_term}
        };
        axios
            .get(GET_MOST_RECENT_MESSAGES_PER_CONVERSATION_PATH, query)
            .then(
                (response) => {
                    state.mostRecentMessages = response.data
                    state.isRequestComplete = true
                    if(state.mostRecentMessages && state.mostRecentMessages.messages && state.mostRecentMessages.messages.length > 0 && (params.hasOwnProperty('mobileView') && !params.mobileView )){
                        state.selectedConversationId = state.mostRecentMessages.messages[0].conversation.id
                        this.dispatch("showConversation")
                    }
                },
                (error) => {
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    showConversation({commit}) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
            current_page: 1
        };
        axios
            .get(`${CONVERSATION_BASE_PATH}/${state.selectedConversationId}`, query)
            .then(
                ({ data }) => {
                    state.selectedConversationId = data.conversation.id
                    state.selectedConversation   = data
                    state.isRequestComplete      = true
                },
                (error) => {
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    reply({commit}, messageContent) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
        };
        axios.post(
            `${CONVERSATION_BASE_PATH}/${state.selectedConversation.conversation.id}/reply`,
            {message_content: messageContent},
            config
        )
            .then(
                (response) => {
                    state.hasAlert = true
                    state.alertMessage = "Sent!"
                    state.alertStatus = "success"
                    this.dispatch("showConversation")
                    state.isRequestComplete = true
                },
                (error) => {
                    state.hasAlert = true
                    state.alertStatus = "error"
                    state.alertMessage = "The message was not sent. Please try again."
                    console.log(error);
                    state.isRequestComplete = true
                }
            )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    archiveConversations({commit}, conversationIds) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
        };

        axios.patch(
            `${CONVERSATION_BASE_PATH}/archive`,
            {ids: conversationIds},
            config
        ).then(
            (response) => {
                state.hasAlert = true
                state.alertMessage = "Message Archived"
                state.alertStatus = "success"
                state.selectedConversationId = null
                state.selectedConversation = null
                this.dispatch("getMostRecentMessagesPerConversation", {isArchived: false})
                state.isRequestComplete = true
            },
            (error) => {
                state.hasAlert = true
                state.alertStatus = "error"
                state.alertMessage = "The message was not archived. Please try again."
                console.log(error);
                state.isRequestComplete = true
            }
        )
            .catch(function (error) {
                console.log(error);
                state.isRequestComplete = true
            });
    },
    unArchiveConversations({commit}, conversationIds) {
        state.isRequestComplete = false
        let query = Object.assign({}, config);
        query.params = {
            "X-CSRF-TOKEN": state.authToken,
        };

        axios.patch(
            `${CONVERSATION_BASE_PATH}/restore`,
            {ids: conversationIds},
            config
        ).then(
            (response) => {
                state.hasAlert = true
                state.alertMessage = "Message Unarchived"
                state.alertStatus = "success"
                state.selectedConversationId = null
                state.selectedConversation = null
                this.dispatch("getMostRecentMessagesPerConversation", {isArchived: true})
                state.isRequestComplete = true
            },
            (error) => {
                state.hasAlert = true
                state.alertStatus = "error"
                state.alertMessage = "The message was not restored. Please try again."
                console.log(error);
                state.isRequestComplete = true
            }
        ).catch(function (error) {
            console.log(error);
            state.isRequestComplete = true
        });
    },
    getDemographics({commit}) {
        let query = Object.assign({}, config);
        query.params = {
            portal_users_id: state.user.id,
        };
        axios
            .get(DEMOGRAPHICS_PATH, query)
            .then(
                (response) => {
                    commit("SET_DEMOGRAPHICS", response.data);
                },
                (error) => {
                    console.log(error);
                }
            )
            .catch(function (error) {
                console.log(error);
            });
    },
    submitDemographics({commit}) {
        if (state.demographics.id) {
            axios
                .put(
                    DEMOGRAPHICS_PATH + "/" + state.demographics.id,
                    state.demographics,
                    config
                )
                .then(
                    (response) => {
                        commit("SUBMIT_DEMOGRAPHICS", response.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            state.demographics.portal_users_id = state.user.id;
            axios
                .post(
                    DEMOGRAPHICS_PATH,
                    {demographic: state.demographics},
                    config
                )
                .then(
                    (response) => {
                        commit("SUBMIT_DEMOGRAPHICS", response.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    getAddress({commit}) {
        let query = Object.assign({}, config);
        query.params = {
            portal_users_id: state.user.id,
        };
        axios
            .get(ADDRESS_PATH, query)
            .then(
                (response) => {
                    commit("SET_ADDRESS", response.data);
                },
                (error) => {
                    console.log(error);
                }
            )
            .catch(function (error) {
                console.log(error);
            });
    },
    submitAddress({commit}) {
        if (state.address.id) {
            axios
                .put(
                    ADDRESS_PATH + "/" + state.address.id,
                    state.address,
                    config
                )
                .then(
                    (response) => {
                        commit("SUBMIT_ADDRESS", response.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            state.address.portal_users_id = state.user.id;
            axios
                .post(ADDRESS_PATH, state.address, config)
                .then(
                    (response) => {
                        commit("SUBMIT_ADDRESS", response.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    getContact({commit}) {
        let query = Object.assign({}, config);
        query.params = {
            portal_users_id: state.user.id,
        };
        axios
            .get(CONTACTS_PATH, query)
            .then(
                (response) => {
                    commit("SET_CONTACT", response.data);
                },
                (error) => {
                    console.log(error);
                }
            )
            .catch(function (error) {
                console.log(error);
            });
    },
    submitContact({commit}) {
        if (state.contact.id) {
            axios
                .put(
                    CONTACTS_PATH + "/" + state.contact.id,
                    state.contact,
                    config
                )
                .then(
                    (response) => {
                        commit("SUBMIT_CONTACT", response.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            state.contact.portal_users_id = state.user.id;
            axios
                .post(CONTACTS_PATH, state.contact, config)
                .then(
                    (response) => {
                        commit("SUBMIT_CONTACT", response.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    goBack({commit}) {
        commit("GO_BACK", state.current_step - 1);
    },
};

const mutations = {
    SET_DEMOGRAPHICS(state, demographics) {
        state.demographics = demographics;
    },
    SET_REGARDING_LIST(state, messageRegardingList) {
        state.messageRegardingList = messageRegardingList;
    },
    SET_RECIPIENTS_LIST(state, messageRecipientsList) {
        state.messageRecipientsList = messageRecipientsList;
    },
    SET_ADDRESS(state, address) {
        state.address = address;
    },
    SET_CONTACT(state, contact) {
        state.contact = contact;
    },
    SUBMIT_DEMOGRAPHICS(state, data) {
        state.current_step = data.step;
        state.demographics = data.demographics;
        state.bufferValue = 0;
    },
    SUBMIT_ADDRESS(state, data) {
        state.current_step = data.step;
        state.address = data.address;
        state.bufferValue = 0;
    },
    SUBMIT_CONTACT(state, data) {
        state.current_step = data.step;
        state.address = data.contact;
        state.user = data.user;
        state.bufferValue = 0;
        state.isOnboarding = false;
    },
    GO_BACK(state, step) {
        state.current_step = step;
        state.bufferValue = 0;
    },
    SET_USER(state, user) {
        state.user = user;
    },
};

const formatName = (item) => {
    let fullname = item.name.full ? item.name.full : item.name
    fullname += item.metadata.admission_date ? " " + new Date(item.metadata.admission_date).toLocaleDateString() : ""
    fullname += item.metadata.discharge_date ? " - " + new Date(item.metadata.discharge_date).toLocaleDateString() : item.metadata.admission_date ? " -  Present" : ""
    fullname += item.metadata.care_team_function ? " (" + item.metadata.care_team_function + ")" : ""
    // nil guard + explicit check, since function used for different items from different messaging service responses
    if (item?.is_enabled === false) fullname += ' ( Messaging Not Available )'
    return fullname
}

const formatData = (list) => {
    list.forEach(item => {
        item.fullname = formatName(item)
    })
    return list
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
});
