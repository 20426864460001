<template>
  <v-card width="620" v-bind="$attrs" v-on="$listeners">
    <div class="mt-15">
      <slot name="header">
        <div class="d-flex justify-center">
          <v-img
              contain
              max-width="193"
              max-height="196"
              :src="branding.clientLogoUrl"
          ></v-img>
        </div>
      </slot>
      <v-card-subtitle class="tw-mt-4">
        <slot name="subtitle"></slot>
      </v-card-subtitle>
      <v-card-text>
        <slot></slot>
      </v-card-text>

      <v-card-actions class="center mt-4">
        <slot name="actions"></slot>
      </v-card-actions>
      <slot name="footer">
        <div class="d-flex justify-center">
          <v-img
              max-width="133"
              max-height="33"
              :src="branding.sponsoredLogoUrl"
          ></v-img>
        </div>
      </slot>
    </div>
      <v-row v-if="is_terms_visible" justify="center" class="mt-8" align="center">
          <a target="_blank" :href="tos_url" style="text-decoration: none;">Terms of Service & Privacy Policy</a>
      </v-row>
  </v-card>
</template>

<script>
export default {
  name: "AuthCard",
    props: {
        is_terms_visible : {
            type: Boolean,
            default : true
        },
        tos_path : {
            type: String,
            default :""
        },
        branding: {
            type: Object,
            default() {
                return {
                    clientLogoUrl: "https://via.placeholder.com/193x96",
                    sponsoredLogoUrl: "https://via.placeholder.com/133x33"
                }
            }
        }
  },
    computed: {
        tos_url() {
            return this.$store.state.tos_path
           // return window.location.origin + "/portal/tos";
        }
  }
}
</script>