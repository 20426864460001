import Vue from 'vue'
import vuetify from "@/plugins/portal_vuetify"; // exports new instance

import Signup from '@/components/portal/users/registrations/signup'
import PortalSignin from '@/components/portal/users/signin'
import ResetPassword from '@/components/portal/users/ResetPassword'
import Invitation from '@/components/portal/users/registrations/invitation'
import portalStore from "@/store/portal";

function mountSignup(){
  const el = document.getElementById('portal_signup_holder_div');
  if (!el) return;

  const data = Object.assign({}, el.dataset); // {} if we don't set data on el in ruby
  const props = JSON.parse(data.props || '{}')

  new Vue({
    el,
    vuetify,
    store: portalStore,
    render: h => h(Signup, {props: props})
  })
}

function mountSignin(){
  const el = document.getElementById('portal_signin_form');
  if (!el) return;

  const data = Object.assign({}, el.dataset); // {} if we don't set data on el in ruby

  // JSON so we aren't limited to strings eg we keep dates, ints and bools from ruby
  // as well as not having our ruby snake_case attributes turn into js camelCase which happens
  // on multi-word snake_cased attributes.
  const props = JSON.parse(data.props || '{}')

  new Vue({
    el,
    vuetify,
    store: portalStore,
    render: h => h(PortalSignin, { props: props })
  });
}

function mountInvitation(){
  const el = document.getElementById('portal_invitation_holder_div');
  if (!el) return;
  const data = Object.assign({}, el.dataset); // {} if we don't set data on el in ruby
  const props = JSON.parse(data.props || '{}');

  new Vue({
    el,
    vuetify,
    store: portalStore,
    render: h => h(Invitation, { props: props })
  })
}

function mountOnboarding(){
  const el = document.getElementById('onboarding');
  if (!el) return;

  new Vue({
    el,
    vuetify,
    store: portalStore,
    render: h => h(Onboard)
  })
}

function mountResetPassword(){
  const el = document.getElementById('portal_reset_password_form');
  if (!el) return;

  const data = Object.assign({}, el.dataset); // {} if we don't set data on el in ruby
  const props = JSON.parse(data.props || '{}')

  new Vue({
    el,
    vuetify,
    store: portalStore,
    render: h => h(ResetPassword, { props: props })
  });
}

function setTOS(){
  portalStore.state.tos_path =  window.location.origin + "/portal/tos";
}

packLoader(() => {
  setTOS()
  mountSignup();
  mountSignin();
  mountInvitation();
  mountOnboarding();
  mountResetPassword();
});
