<template>
  <auth-card :branding="branding" :is_terms_visible="false">
    <template v-slot:subtitle>
      <div class="tw-text-center black--text">
          Create your password to access the portal
      </div>

      <message-box class="tw-text-xs" :messages="messages" />
    </template>

    <template v-slot:default>
      <v-text-field
        outlined
        label="Enter Password"
        :type="showPassword ? 'text' : 'password'"
        v-model="password"
        name="portal_user[password]"
        autocomplete="new-password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        v-bind:hint="hint"
        :rules="[rules.required, rules.min]"
      />
      <v-text-field
        outlined
        label="Confirm Password"
        :type="showPasswordConfirmation ? 'text' : 'password'"
        v-model="passwordConfirmation"
        name="portal_user[password_confirmation]"
        autocomplete="new-password"
        :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPasswordConfirmation = !showPasswordConfirmation"
        v-bind:hint="hint"
        :rules="[rules.required, rules.passwordMatch]"
      />
      <v-row justify="center" class="pt-2">
        <v-checkbox :label="`I agree to\xa0`" v-model="isEnabled" style="margin-top: -7px;"/>
        <a target="_blank" :href="tos_url" style="text-decoration: none;">
          Terms of Service & Privacy Policy
        </a>
      </v-row>
      <v-btn
        block
        :dark="isEnabled"
        rounded
        color="blue darken-4"
        type="submit"
        name="commit"
        class="white--text mt-2"
        value="Set my password"
        :disabled="!isEnabled"
      >
        Create
      </v-btn>
    </template>
  </auth-card>
</template>

<script>
import MessageBox from "@/components/portal/alerts/MessageBox";
import AuthCard from "../AuthCard";

export default {
  components: { MessageBox, AuthCard },
  props: {
    branding: {
      type: Object,
      default() {
        return {
          clientLogoUrl: "https://via.placeholder.com/193x96",
          sponsoredLogoUrl: "https://via.placeholder.com/133x33"
        }
      }
    },
    server_messages: { type: Array, default: [] }
  },
  data: function () {
    return {
      button_label: "Submit",
      title: "Create Password",
      password: '',
      passwordConfirmation: '',
      showPassword: false,
      showPasswordConfirmation: false,
      isEnabled: false,
      agreementLabel: "I agree to &nbsp;",
      hint: 'Enter a hard to guess key combination',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        passwordMatch: () => (this.password === this.passwordConfirmation) || `The password you entered don't match`
      },
      messages: this.server_messages
    }
  },
  name: 'CreatePassword',
  icons: {
    iconfont: 'md'
  },
  methods: {
    addMessage({ success, message }) {
      const msgType = success ? "success" : "error";
      this.messages = [{ message_type: msgType, text: message }];
    },
    anyMessage() {
      return this.messages.length > 0;
    }
  },
  computed: {
    tos_url() {
      return this.$store.state.tos_path
      // return window.location.origin + "/portal/tos";
    }
  }
}
</script>
