<template>
  <auth-card :branding="branding">
    <template v-slot:subtitle>
      <message-box class="tw-text-xs" :messages="messages"></message-box>
    </template>

    <template v-slot:default>
      <v-text-field name="portal_user[first_name]"
                    label="First Name"
                    v-model="form.first_name"
                    type="text"
                    class="mt-10"
                    outlined
                    required
      ></v-text-field>
      <v-text-field name="portal_user[last_name]"
                    label="Last Name"
                    v-model="form.last_name"
                    type="text"
                    outlined
                    required
      ></v-text-field>
      <v-text-field name="portal_user[email]"
                    label="Email"
                    v-model="form.email"
                    type="email"
                    :rules="emailRules"
                    outlined
                    required
      ></v-text-field>
      <v-text-field name="portal_user[password]"
                    label="Password"
                    v-model="form.password"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    outlined
                    required
      ></v-text-field>
      <v-text-field name="portal_user[password_confirmation]"
                    label="Password Confirmation"
                    v-model="form.password_confirmation"
                    :type="showPasswordConf ? 'text' : 'password'"
                    :append-icon="showPasswordConf ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPasswordConf = !showPasswordConf"
                    outlined
      ></v-text-field>
      <v-btn block
             dark
             rounded
             color="blue darken-4"
             @click="validateForm"
             type="submit"
             value="Sign up"
             :disabled="!formValidity">Sign up
      </v-btn>
    </template>

    <template v-slot:actions>
      <v-btn plain color="blue darken-4" v-on:click="login_button_click" class="text-capitalize">
        Login
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn plain color="blue darken-4" v-on:click="forgot_password_button_click" class="text-capitalize">
        Forgot Password?
      </v-btn>
    </template>
  </auth-card>
</template>

<script>
import MessageBox from "../../alerts/MessageBox";
import AuthCard from "../AuthCard";

export default {
  components: {AuthCard, MessageBox},
  props: {
    sign_in_path: {type: String, default: ""},
    messages: {
      type: Array,
      default() {
        return [];
      }
    },
    form: {
      type: Object,
      default() {
        return {
          first_name: "",
          last_name: "",
          email: "",
          password: null,
          password_confirmation: null
        }
      }
    },
    branding: {
      type: Object,
      default() {
        return {
          clientLogoUrl: "https://via.placeholder.com/193x96",
          sponsoredLogoUrl: "https://via.placeholder.com/133x33"
        }
      }
    }
  },
  data: function () {
    return {
      showPassword: false,
      showPasswordConf: false,
      emailRules: [
        value => !!value || 'Email is required.',
        value => value.indexOf('@') !== 0 || 'Email should have a username.',
        value => value.includes('@') || 'Email should include an @ symbol.',
        value => value.includes('.') || 'Email should include a period symbol.',
      ],
      formValidity: true
    }
  },

  methods: {
    // resetForm() {
    //   this.$refs.signUpForm.reset()
    // },
    // resetValidation() {
    //   this.$refs.signUpForm.resetValidation()
    // },
    forgot_password_button_click: function (e) {
      alert("Forgot password screen coming soon!")
    },
    login_button_click: function (e) {
      window.location.href = this.sign_in_path;
    },
    validateForm() {
      // this.$refs.signUpForm.validate()
    }
  }
}
</script>
